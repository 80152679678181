import React, { createContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import Header from 'src/components/Header';
import Employer from 'src/components/Employer';
import Education from 'src/components/Education';
import Knowledge from 'src/components/Knowledge';
import Language from 'src/components/Language';
import Network from 'src/components/Network';
import Work from 'src/components/Work';
import Footer from 'src/components/Footer';
import Translation from 'src/components/Translation';
import Loader from 'src/components/tiny/Loader';
import {
  Container,
  LeftPanel as OrignalLeftPanel,
  RightPanel as OriginalRightPanel
} from 'src/styles/styles';
import { getData } from 'src/utils';
import { Locale } from 'src/types';
import { find } from 'lodash';
import { isValidGaDomain, getLocale } from 'src/utils/index';
import ReactGA from 'react-ga4';

interface ContextTypes {
  isPrinting?: boolean;
}

export const AppContext = createContext<ContextTypes>({ isPrinting: false });

const leftPanelMobileStylings = css`
  order: 2;
`;

const leftPanelTabletStylings = css`
  order: 1;
`;

const rightPanelMobileStylings = css`
  order: 1;
`;

const rightPanelTabletStylings = css`
  order: 2;
`;

const LeftPanel = styled(OrignalLeftPanel)`
  ${leftPanelTabletStylings}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${leftPanelMobileStylings}
  }

  @media print {
    ${leftPanelTabletStylings}

    padding-top: 0;
    padding-bottom: 0;

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${leftPanelMobileStylings}
    }
  }
`;

const RightPanel = styled(OriginalRightPanel)`
  ${rightPanelTabletStylings}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${rightPanelMobileStylings}
  }

  @media print {
    ${rightPanelTabletStylings}

    padding-top: 0;
    padding-bottom: 0;

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${rightPanelMobileStylings}
    }
  }
`;

const mobileStyling = css`
  padding-top: ${(props) => theme.getMobileVw(props.theme.generalSpacing * 2)};
  padding-bottom: ${(props) =>
    theme.getMobileVw(props.theme.generalSpacing * 2)};

  .main {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${(props) => theme.getMobileVw(props.theme.generalSpacing)};
    padding-bottom: ${(props) => theme.getMobileVw(props.theme.generalSpacing)};

    .leftpanel {
      order: 2;

      > * {
        //.history
        > .separator:last-child {
          border: none !important;
        }
        &:last-child {
          .separator:last-child {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
          }
        }
      }
    }

    .rightpanel {
      order: 1;

      > *:last-child {
        // .work
        margin-bottom: 0;
        padding-bottom: 0;

        > *:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const tabletStyling = css`
  padding-top: ${(props) => theme.rem(props.theme.generalSpacing * 2)};
  padding-bottom: ${(props) => theme.rem(props.theme.generalSpacing * 2)};

  .main {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${(props) => theme.rem(props.theme.generalSpacing)};
    padding-bottom: ${(props) => theme.rem(props.theme.generalSpacing)};

    .leftpanel {
      order: 1;

      > * {
        //.history
        > .separator:last-child {
          border: none !important;
        }
        &:last-child {
          .separator:last-child {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
          }
        }
      }
    }

    .rightpanel {
      order: 2;

      > *:last-child {
        // .work
        margin-bottom: 0;
        padding-bottom: 0;

        > *:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const StyledComponent = styled.div`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

function App() {
  const [contextData, setContexData] = useState<ContextTypes>({
    isPrinting: false
  });
  const [siteTile, setSiteTitle] = useState<string>('');
  const [copyright, setCopyright] = useState<string>();
  const [educationTitle, setEducationTitle] = useState<string>();
  const [employerTitle, setEmployerTitle] = useState<string>();
  const [knowledgeTitle, setKnowledgeTitle] = useState<string>();
  const [languageTitle, setLanguageTitle] = useState<string>();
  const [networkTitle, setNetworkTitle] = useState<string>();
  const [workTitle, setWorkTitle] = useState<string>();
  const [titleIsLoaded, setTitleIsLoaded] = useState<boolean>(false);
  const [locale, setLocale] = useState<Locale>(getLocale());

  document.documentElement.lang = locale;

  useEffect(() => {
    getData('site', locale).then((result) => {
      if (result.isSucceed) {
        const siteData = find(result.data, { name: 'title' });
        setSiteTitle(siteData.data);

        const copyrightData = find(result.data, { name: 'copyright' });
        setCopyright(copyrightData.data);
      }
    });

    if (isValidGaDomain()) {
      // Track the initial language when the user visits the site
      ReactGA.event({
        category: 'User Interaction',
        action: 'custom_site_load',
        label: `Using Language: ${locale}`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    document.title = siteTile;
  }, [siteTile]);

  useEffect(() => {
    setTitleIsLoaded(false);
    getData('title', locale).then((result) => {
      if (result.isSucceed) {
        const educationTitle = find(result.data, { id: 1 });
        const employerTitle = find(result.data, { id: 2 });
        const knowledgeTitle = find(result.data, { id: 3 });
        const languageTitle = find(result.data, { id: 4 });
        const networkTitle = find(result.data, { id: 5 });
        const workTitle = find(result.data, { id: 6 });
        setEducationTitle(educationTitle?.title || '');
        setEmployerTitle(employerTitle?.title || '');
        setKnowledgeTitle(knowledgeTitle?.title || '');
        setLanguageTitle(languageTitle?.title || '');
        setNetworkTitle(networkTitle?.title || '');
        setWorkTitle(workTitle?.title || '');
        setTitleIsLoaded(true);
      }
    });
  }, [locale]);

  useEffect(() => {
    const handleBeforePrint = () => {
      // this only inciates the user has opened print dialog, but does not know if he/she has printed it
      setContexData((prevState: ContextTypes) => ({
        ...prevState,
        isPrinting: true
      }));

      if (isValidGaDomain()) {
        // Track the initial language when the user visits the site
        ReactGA.event({
          category: 'User Interaction',
          action: 'custom_print',
          label: `Print dialog opned`
        });
      }
    };

    const handleAfterPrint = () => {
      setContexData((prevState: ContextTypes) => ({
        ...prevState,
        isPrinting: false
      }));
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint); // when print dialog is shown
      window.removeEventListener('afterprint', handleAfterPrint); // when print dialog is closed
    };
  }, []);

  return (
    <AppContext.Provider value={contextData}>
      {titleIsLoaded ? (
        <StyledComponent>
          <Header locale={locale} />
          <Container className="main">
            <LeftPanel>
              <Employer
                className="employer"
                locale={locale}
                title={employerTitle}
              />
              <Education
                className="education"
                locale={locale}
                title={educationTitle}
              />
            </LeftPanel>
            <RightPanel>
              <Knowledge
                className="knowledge"
                locale={locale}
                title={knowledgeTitle}
              />
              <Language
                className="language"
                locale={locale}
                title={languageTitle}
              />
              <Network
                className="network"
                locale={locale}
                title={networkTitle}
              />
              <Work className="work" locale={locale} title={workTitle} />
            </RightPanel>
          </Container>
          {copyright && <Footer className="footer" copyright={copyright} />}
          <Translation locale={locale} setLocale={setLocale} />
        </StyledComponent>
      ) : (
        <div style={{ height: '100vh', display: 'flex' }}>
          <Loader />
        </div>
      )}
    </AppContext.Provider>
  );
}

export default App;
