import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import { caseConversion, isHtml } from 'src/utils';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  caseType?: 'UPPER' | 'LOWER' | 'TITLE';
  heading: string;
  tag?: string;
  type?: 'main' | 'sub';
}

const mainHeadingFontSize = 25;
const mainHeadingMargin = 10;
const subHeadingFontSize = 20;
const subHeadingMargin = 13;

const mobileMainHeadingStyling = css`
  color: ${theme.colors.themeColorBlue};
  font-size: ${theme.getMobileVw(mainHeadingFontSize)};
  margin-bottom: ${theme.getMobileVw(mainHeadingMargin)};
`;

const tabletMainHeadingStyling = css`
  color: ${theme.colors.themeColorBlue};
  font-size: ${theme.rem(mainHeadingFontSize)};
  margin-bottom: ${theme.rem(mainHeadingMargin)};
`;

const mobileSubHeadingStyling = css`
  color: ${theme.colors.themeColorPink};
  font-size: ${theme.getMobileVw(subHeadingFontSize)};
  margin-bottom: ${theme.getMobileVw(subHeadingMargin)};
`;

const tabletSubHeadingStyling = css`
  color: ${theme.colors.themeColorPink};
  font-size: ${theme.rem(subHeadingFontSize)};
  margin-bottom: ${theme.rem(subHeadingMargin)};
`;

const StyledMainHeading = styled.div`
  ${tabletMainHeadingStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileMainHeadingStyling}
  }

  @media print {
    ${tabletMainHeadingStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileMainHeadingStyling}
    }
  }
`;

const StyledSubHeading = styled.div`
  ${tabletSubHeadingStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileSubHeadingStyling}
  }

  @media print {
    ${tabletSubHeadingStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileSubHeadingStyling}
    }
  }
`;

const Heading: FC<Props> = ({
  caseType,
  heading,
  tag = 'p',
  type = 'main',
  ...rest
}) => {
  const isHeadingHtml = isHtml(heading);
  const isMainHeading = type.toLowerCase() === 'main';
  const className = `${isMainHeading ? 'mainheading' : 'subheading'}`;
  const StyledHeading = isMainHeading ? StyledMainHeading : StyledSubHeading;
  const str = caseConversion(heading, caseType);

  const renderHeading = () => {
    if (isHeadingHtml) {
      return (
        <StyledHeading
          as={tag}
          dangerouslySetInnerHTML={{ __html: str }}
          {...rest}
          className={`${className}${
            rest.className ? ` ${rest.className}` : ''
          }`}
        />
      );
    }
    return (
      <StyledHeading className={className} {...rest}>
        {str}
      </StyledHeading>
    );
  };

  return str ? renderHeading() : null;
};

export default Heading;
