const mobileFontSize = 16;
const desktopFontSize = 24;
const theme = {
  generalSpacing: 20,
  generalFontSize: 17,
  rightSidebarBottomSpacing: 15,
  colors: {
    themeColorBlue: '#205081',
    themeColorPink: '#eb018c'
  },
  breakpoints: {
    tiny: 320,
    mobile: 480,
    tablet: 769,
    desktop: 969,
    wrapper: 1170
  },
  getDesktopVw: (width: number | string): string => {
    if (typeof width === 'string' && width.endsWith('%')) {
      return width;
    }
    const numericWidth = typeof width === 'string' ? parseFloat(width) : width;
    return `${(numericWidth / 769) * 100}vw`;
  },
  getMobileVw: (width: number | string): string => {
    if (typeof width === 'string' && width.endsWith('%')) {
      return width;
    }
    const numericWidth = typeof width === 'string' ? parseFloat(width) : width;
    return `${(numericWidth / 480) * 100}vw`;
  },
  getDesktopFontSize: (size: number) => `${(size / desktopFontSize) * 100}rem`,
  getMobileFontSize: (size: number) => `${(size / mobileFontSize) * 100}rem`,
  pixel: (size: number) => `${size}px`,
  rem: (size: number) => `${size / 16}rem`
};

export default theme;
