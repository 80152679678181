import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import Title from 'src/components/tiny/Title';
import Heading from 'src/components/tiny/Heading';
import IconText from 'src/components/tiny/IconText';
import Point from 'src/components/tiny/Point';
import Separator from 'src/components/Separator';
import Loader from 'src/components/tiny/Loader';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  data?: {
    heading: string;
    subHeading?: string;
    subheading?: string;
    date?: string;
    location?: string;
    details: string[];
  }[];
}

const mobileStyling = css`
  width: 100%;

  > .separator:last-child {
    border: none;
  }
  &:last-child {
    .separator:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .each-history {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    > .headings {
      width: 100%;

      [class*='mainheading'],
      [class*='subheading'] {
        flex-basis: 100%;
      }
    }

    > .details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .icontext {
        flex-grow: 1;
        margin-right: 0;

        &.gps-black {
          flex-grow: 10;
        }
      }
    }
  }
`;

const tabletStyling = css`
  width: 100%;

  > .separator:last-child {
    border: none;
  }
  &:last-child {
    .separator:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .each-history {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    > .headings {
      [class*='mainheading'],
      [class*='subheading'] {
        flex-basis: 100%;
      }
    }

    > .details {
      display: flex;
      flex-basis: 100%;
      flex-direction: row;

      .icontext {
        flex-grow: 1;
        margin-right: 0;

        &.gps-black {
          flex-grow: 10;
        }
      }
    }
  }
`;

const StyledComponent = styled.div`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}
    // BEGIN :: this styling makes details align to the right
    // .each-history {
    //   > .heading,
    //   > .details {
    //     display: block;
    //     > * {
    //       text-align: right;
    //     }
    //   }

    //   > .details {
    //     margin-left: auto;
    //     flex-basis: auto;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: flex-end;
    //   }
    // }
    // END :: this styling makes details align to the right

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const History: FC<Props> = ({ title, data, ...rest }) => {
  return (
    <StyledComponent {...rest}>
      <Title title={title} caseType="UPPER" />
      {data && data.length > 0 ? (
        data.map((each, index) => {
          const EachHistory = (
            <div className="each-history">
              <div className="headings">
                <Heading heading={each.heading} />
                {each.subHeading || each.subheading ? (
                  <Heading
                    heading={each.subHeading || each.subheading || ''}
                    type="sub"
                  />
                ) : null}
              </div>
              <div className="details">
                {each.date ? (
                  <IconText
                    text={each.date}
                    icon="calendar"
                    className="icontext"
                  />
                ) : null}
                {each.location ? (
                  <IconText
                    text={each.location}
                    icon="gps-black"
                    className="icontext"
                  />
                ) : null}
              </div>
              <Point points={each.details} />
            </div>
          );

          const NewDashedComp = (
            <Separator WrappedComponent={EachHistory} className="separator" />
          );

          return React.cloneElement(NewDashedComp, {
            ...each,
            key: index
          });
        })
      ) : (
        <Loader />
      )}
    </StyledComponent>
  );
};

export default History;
