import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import { isValidGaDomain } from 'src/utils/index';
import ReactGA from 'react-ga4';

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  url: string;
  children?: ReactNode;
}

const margin = 15;
const roundedRadius = 6;
const spacing = 10;

const handleClick = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  const url = event.currentTarget.href;
  // Track the link click event
  ReactGA.event({
    category: 'User Interaction',
    action: 'custom_link_click',
    label: `Clicked link to ${url}`
  });
};

const mobileStyling = css`
  border-radius: ${theme.getMobileVw(roundedRadius)};
  align-items: center;
  background-color: ${theme.colors.themeColorBlue};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.getMobileVw(margin)};
  padding: ${theme.getMobileVw(spacing)} ${theme.getMobileVw(spacing)}
    ${theme.getMobileVw(spacing)} 0;
  width: auto;
`;

const tabletStyling = css`
  border-radius: ${theme.rem(roundedRadius)};
  align-items: center;
  background-color: ${theme.colors.themeColorBlue};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.rem(margin)};
  padding: ${theme.rem(spacing)} ${theme.rem(spacing)} ${theme.rem(spacing)} 0;
  width: 100%;
`;

const StyledComponent = styled.a`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const Card: FC<Props> = ({ url, children, ...rest }) => {
  return (
    <StyledComponent
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      onClick={isValidGaDomain() ? handleClick : undefined}
      {...rest}
    >
      {children}
    </StyledComponent>
  );
};

export default Card;
