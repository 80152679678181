import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';

interface Props {
  points?: string[];
}

const fontSize = theme.generalFontSize;

const mobileStyling = css`
  flex-basis: 100%;

  > .each-point {
    font-size: ${theme.getMobileVw(fontSize)};
    line-height: 1.25;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const tabletStyling = css`
  flex-basis: 100%;

  > .each-point {
    font-size: ${theme.rem(fontSize)};
    line-height: 1.25;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledComponent = styled.ul`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const Point: FC<Props> = ({ points = [] }) => {
  return (
    <StyledComponent>
      {points?.length &&
        points.map((each, index) => (
          <li
            className="each-point"
            key={index}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: each }}
          />
        ))}
    </StyledComponent>
  );
};

export default Point;
