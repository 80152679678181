import React, { useEffect, useState, FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import { getData } from 'src/utils';
import { LOCALE } from 'src/utils/constants';
import { Locale } from 'src/types';
import Title from 'src/components/tiny/Title';
import QrCard from 'src/components/tiny/QrCard';
import Loader from 'src/components/tiny/Loader';

interface NetworkCardType {
  image: string;
  network: string;
  url: string;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  locale?: Locale;
  qr?: string;
  size?: number;
}

const mobileStyling = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${theme.getMobileVw(theme.rightSidebarBottomSpacing)};
  padding-bottom: ${theme.getMobileVw(theme.rightSidebarBottomSpacing)};
  width: 100%;
`;

const tabletStyling = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${theme.rem(theme.rightSidebarBottomSpacing)};
  padding-bottom: ${theme.rem(theme.rightSidebarBottomSpacing)};
  width: 100%;
`;

const StyledComponent = styled.div`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    // BEGIN :: this styling makes items in qr card align side-by-side
    // .qrcard {
    //   width: calc((100% / 2) - ${theme.rem(theme.generalSpacing / 2)});
    //   flex: 0 0 calc((100% / 2) - ${theme.rem(theme.generalSpacing / 2)});
    // }
    // END :: this styling makes items in qr card align side-by-side

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const Network: FC<Props> = ({
  title,
  locale = LOCALE.DEFAULT,
  qr = '',
  size = 100,
  ...rest
}) => {
  const [data, setData] = useState<NetworkCardType[]>([]);

  useEffect(() => {
    setData([]);
    getData('network', locale).then((result) => {
      if (result.isSucceed) {
        setData(result.data);
      }
    });
  }, [locale]);

  return (
    <StyledComponent {...rest}>
      <Title title={title} caseType="UPPER" />
      {data?.length > 0 ? (
        data.map((each, index) => {
          return (
            <QrCard
              className="qrcard"
              icon={each.image}
              key={index}
              name={each.network}
              url={each.url}
            />
          );
        })
      ) : (
        <Loader />
      )}
    </StyledComponent>
  );
};

export default Network;
