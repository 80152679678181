import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import Card from 'src/components/tiny/Card';
import ImageComp from 'src/components/tiny/ImageComp';
import QrCode from 'src/components/tiny/QrCode';
import { TailSpin } from 'react-loader-spinner';
import { API_HOST } from 'src/utils/constants';

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  name?: string;
  icon?: string;
  url: string;
}

const fontSize = theme.generalFontSize;
const roundedRadius = 6;
const desktopIconSize = 39;
const mobileIconSize = 39;

const mobileStyling = css`
  display: flex;
  flex-direction: row;

  // BEGIN :: this styling makes items in qr card align side-by-side
  flex: 1 1 calc((100% - ${theme.getMobileVw(theme.generalSpacing)}) / 2);
  max-width: calc((100% - ${theme.getMobileVw(theme.generalSpacing)}) / 2);
  // END :: this styling makes items in qr card align side-by-side

  .content-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    justify-content: center;

    &.has-icon {
      justify-content: space-between;
    }

    .loader,
    .icon {
      margin: 0 auto;
      width: ${theme.getMobileVw(mobileIconSize)};
    }

    .name {
      border-radius: ${theme.getMobileVw(roundedRadius)};
      color: #fff;
      display: block;
      font-size: ${theme.getMobileVw(fontSize)};
      font-weight: 700;
      line-height: 1.3;
      text-align: center;
      width: 100%;
    }
  }
`;

const tabletStyling = css`
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  max-width: 100%;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    justify-content: center;

    &.has-icon {
      justify-content: space-between;
    }

    .loader,
    .icon {
      margin: 0 auto;
      width: ${theme.rem(desktopIconSize)};
    }

    .name {
      border-radius: ${theme.getMobileVw(roundedRadius)};
      color: #fff;
      display: block;
      font-size: ${theme.rem(fontSize)};
      font-weight: 700;
      line-height: 1.3;
      text-align: center;
      width: 100%;
    }
  }
`;

const StyledCard = styled(Card)`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const QrCard: FC<Props> = ({ name, icon, url }) => {
  return (
    <StyledCard url={url} className="qrcard">
      <div className={`content-wrapper${icon ? ' has-icon' : ''}`}>
        {icon && (
          <ImageComp
            className="icon"
            src={`${API_HOST}${icon}`}
            loadingIcon={
              <TailSpin
                height="100%"
                width="100%"
                color={theme.colors.themeColorPink}
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="loader-icon"
                visible={true}
              />
            }
          />
        )}
        {name && <p className="name">{name}</p>}
      </div>
      <div className="qr-wrapper">
        <QrCode name={name} url={url} />
      </div>
    </StyledCard>
  );
};

export default QrCard;
