import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';

interface Props {
  name: string;
  level: number;
  tag?: string;
}

const fontSize = theme.generalFontSize;
const margin = 15;

const mobileStyling = css`
  background-color: ${theme.colors.themeColorPink};
  font-size: ${theme.getMobileVw(fontSize)};
  margin-bottom: ${theme.getMobileVw(margin)};
  padding: 0.45em 0 0.45em 1em;
  position: relative;
  width: 100%;

  > .name {
    color: #fff;
    display: block;
    position: relative;
    z-index: 1;
  }

  > .level {
    background-color: ${theme.colors.themeColorBlue};
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
`;

const tabletStyling = css`
  background-color: ${theme.colors.themeColorPink};
  font-size: ${theme.rem(fontSize)};
  margin-bottom: ${theme.rem(margin)};
  padding: 0.45em 0 0.45em 1em;
  position: relative;
  width: 100%;

  > .name {
    color: #fff;
    display: block;
    position: relative;
    z-index: 1;
  }

  > .level {
    background-color: ${theme.colors.themeColorBlue};
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
`;

const StyledComponent = styled.p`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const Meter: FC<Props> = ({ name, level, tag = 'p' }) => {
  return (
    <StyledComponent as={tag}>
      <span className="name">{name}</span>
      <span className="level" style={{ width: `${level}%` }}></span>
    </StyledComponent>
  );
};

export default Meter;
