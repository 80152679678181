import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';

interface IconProps {
  icon: 'calendar' | 'contact' | 'envelope' | 'gps-black' | 'gps-pink';
}

interface Props extends IconProps, React.HTMLAttributes<HTMLParagraphElement> {
  extraClassName?: string;
  tag?: keyof JSX.IntrinsicElements;
  text: string;
  attr?: Object;
  href?: string;
}

const calendar =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAMAAADzN3VRAAAAZlBMVEUAAAA4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c/aZEBAAAAIXRSTlMAAQMEBQYLFUBOT1BSVW97fI+Soquttbe+wMXP1d7o7/UMhn5KAAAAjklEQVQokeWRvQ6CQBgEBzgBRUUFvY8/uX3/l7QQJEYSKyun3NlikwWAXV1vASA53cqEF3tJKgC4SDov5qooUg1AaGnDnMeuEahxzjknj5eLASgkCTTh8ZIOAOOaGWGK0nQ23aZ7VpfyO1+MB/IcwACOgP+FCWY2DGZmwczsbmbhzxb0ayf0AFnlP6gyeACnYyErDJtAzwAAAABJRU5ErkJggg==';
const envelope =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAMAAADzN3VRAAAAe1BMVEUAAADrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYxv/kTyAAAAKHRSTlMAAQIDBAYJChITGCYnKjAxOE1PUFFreHl8m52io6q6vL7ByODm6Pf5/We1uAAAAJlJREFUKJG90skOglAQRNGLICiO4GNwZlCo//9CFxqEICS6sHZdZ9NJN/wUx+3HAUj1KSksP4K0IFQe9+o4V4jRGb/o9IXPWQajDCxTN329syCTwUiXGXinFxw9mF/1FCmawOYu6baGSSQ1onIFdqLEhlWptkh7F6bgHl7zW1QFQFCpL1KxbW3fkU7+J+GAhCP3Gb7pyB98nwdXaj+bVW9KJgAAAABJRU5ErkJggg==';
const gpsBlack =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAMAAADzN3VRAAAAqFBMVEUAAAA4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0c4Q0eU69c0AAAAN3RSTlMAAQIDBgcICwwQER0eIyYvMzY4RkdJSktNTlZXYmNsd3iDl52ipa23ucHDxcfKzOnv8fX3+fv98yyBvgAAALlJREFUGBl1wYlWwjAARcFHCUZwBRR3kVWoC9pK7///mfTkpElrnVFltAbWQzUdf+J8WNXYb7wvq9g7ZGMjM87gTZFLyAcqDXK4ULCEGzl3sFAA9OQcQaFKF5AHdOUlQCInARJVfuBMzjnkCjaw7ajUSeFVwROwMpLMCnhUcMrBfv4833NwosiOYKfYA8G9Yj0Co5oZ3kx1Fs+q4QVnqiaTU8qM/rimdKUWKZCqTb+g6KvVhIn+cavILztKJ7UMLRdxAAAAAElFTkSuQmCC';
const gpsPink =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAMAAADzN3VRAAAAqFBMVEUAAADrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYxTcxyNAAAAN3RSTlMAAQIDBgcICwwQER0eIyYvMzY4RkdJSktNTlZXYmNsd3iDl52ipa23ucHDxcfKzOnv8fX3+fv98yyBvgAAALlJREFUGBl1wYlWwjAARcFHCUZwBRR3kVWoC9pK7///mfTkpElrnVFltAbWQzUdf+J8WNXYb7wvq9g7ZGMjM87gTZFLyAcqDXK4ULCEGzl3sFAA9OQcQaFKF5AHdOUlQCInARJVfuBMzjnkCjaw7ajUSeFVwROwMpLMCnhUcMrBfv4833NwosiOYKfYA8G9Yj0Co5oZ3kx1Fs+q4QVnqiaTU8qM/rimdKUWKZCqTb+g6KvVhIn+cavILztKJ7UMLRdxAAAAAElFTkSuQmCC';
const telephone =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAMAAADzN3VRAAAAt1BMVEUAAADrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYzrAYyRVd5lAAAAPHRSTlMAAQIEBQgKCw8QEhQZGhshIyQvMTI1NkZKUlRdZ2xxeHt/gIKGjI6PlJigoqOosLe81dfa4unv8fP1+ft3wQCOAAAApUlEQVQYGW3Bh1bCQBQFwIthpSu9I9I7SpSEcv//u8huDqS8nYHWqcBuQd+BzYrkEFJmzcBdQRjRmEHwGCog7Y+hL6RtGKojrUujDyFHzYfFnloeUpnaFhZLaj1I2TO1NqQqjRakPo0eAsVJAzFjGrscPq7kTwmROUOHG7Wxg5dvJnifeBkwYY5I/cKIqxDzfuSTq5DUPNFwFYTa9J/8VbB6c2A8ALA7LH0uyAO/AAAAAElFTkSuQmCC';
const fontSize = theme.generalFontSize;
const iconSize = 17;
const margin = 10;

const getIconBackgroundImage = (icon: string) => {
  switch (icon) {
    case 'calendar':
      return calendar;
    case 'envelope':
      return envelope;
    case 'gps-black':
      return gpsBlack;
    case 'gps-pink':
      return gpsPink;
    case 'telephone':
      return telephone;
    default:
      return 'none';
  }
};

const mobileStyling = css`
  align-items: center;
  display: flex;
  font-size: ${theme.getMobileVw(fontSize)};
  margin-bottom: ${theme.getMobileVw(margin)};

  &:before {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${calendar});
    content: '';
    display: inline-block;
    height: ${theme.getMobileVw(iconSize)};
    margin-right: ${parseFloat(theme.getMobileVw(iconSize)) / 2}vw;
    width: ${theme.getMobileVw(iconSize)};
  }

  &.calendar:before {
    background-image: url(${calendar});
  }
  &.envelope:before {
    background-image: url(${envelope});
  }
  &.gps-black:before {
    background-image: url(${gpsBlack});
  }
  &.gps-pink:before {
    background-image: url(${gpsPink});
  }
  &.telephone:before {
    background-image: url(${telephone});
  }
`;

const tabletStyling = css`
  align-items: center;
  display: flex;
  font-size: ${theme.rem(fontSize)};
  margin-bottom: ${theme.rem(margin)};

  &:before {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${calendar});
    content: '';
    display: inline-block;
    height: ${theme.rem(iconSize)};
    margin-right: ${parseFloat(theme.rem(iconSize)) / 2}rem;
    width: ${theme.rem(iconSize)};
  }

  &.calendar:before {
    background-image: url(${calendar});
  }
  &.envelope:before {
    background-image: url(${envelope});
  }
  &.gps-black:before {
    background-image: url(${gpsBlack});
  }
  &.gps-pink:before {
    background-image: url(${gpsPink});
  }
  &.telephone:before {
    background-image: url(${telephone});
  }
`;

const StyledComponent = styled.p<IconProps>`
  ${tabletStyling}

  &:before {
    background-image: url(${(props) => getIconBackgroundImage(props.icon)});
  }

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}
    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const IconText: FC<Props> = ({ icon, tag = 'p', text, ...rest }) => {
  const className = rest.className?.trim()
    ? `${rest.className?.trim()} ${icon}`
    : icon;

  return (
    <StyledComponent as={tag} icon={icon} {...rest} className={className}>
      {text}
    </StyledComponent>
  );
};

export default IconText;
