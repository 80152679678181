import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  copyright: string;
}

const fontSize = 13;

const mobileStyling = css`
  align-items: center;
  color: #b5b4b4;
  display: flex;
  font-size: ${theme.getMobileVw(fontSize)};
  font-style: italic;
  justify-content: center;
`;

const tabletStyling = css`
  align-items: center;
  color: #b5b4b4;
  display: flex;
  font-size: ${theme.rem(fontSize)};
  font-style: italic;
  justify-content: center;
`;

const StyledComponent = styled.footer`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const Footer: FC<Props> = ({ copyright, ...rest }) => {
  function replaceTemplates(
    inputString: string,
    replacements: { [key: string]: string }
  ): string {
    // Use a regular expression with case-sensitive matching to find placeholders within curly braces
    const regex = /{([^{}]+)}/g;

    // Replace each placeholder with the corresponding value from the replacements object
    const replacedString = inputString.replace(regex, (match, key) => {
      const replacement = replacements[key];
      return replacement !== undefined ? replacement : match;
    });

    return replacedString;
  }

  const year = String(new Date().getFullYear());
  const domain = (() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params['copyright-domain']) {
      return params['copyright-domain'];
    }
    return `${window.location.protocol}//${window.location.host}`;
  })();
  const newCopyright = replaceTemplates(copyright, {
    year,
    domain
  });

  return (
    <StyledComponent as={'footer'} {...rest}>
      {newCopyright}
    </StyledComponent>
  );
};

export default Footer;
