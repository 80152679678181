import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import ImageComp from 'src/components/tiny/ImageComp';
import { TailSpin } from 'react-loader-spinner';
import { API_HOST } from 'src/utils/constants';

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  name?: string;
  qr?: string;
  size?: number;
  url: string;
}

const qrSize = 65;
const roundedRadius = 6;

const mobileStyling = css`
  // .qr-img,
  // .loader {
  //   border-radius: ${theme.getMobileVw(roundedRadius)};
  //   display: block;
  //   width: ${theme.getMobileVw(qrSize)};
  // }
  border-radius: ${theme.getMobileVw(roundedRadius)};
  display: block;
  width: ${theme.getMobileVw(qrSize)};
`;

const tabletStyling = css`
  // .qr-img,
  // .loader {
  //   border-radius: ${theme.rem(roundedRadius)};
  //   display: block;
  //   width: ${theme.rem(qrSize)};
  // }
  border-radius: ${theme.rem(roundedRadius)};
  display: block;
  width: ${theme.rem(qrSize)};
`;

const StyledImageCard = styled(ImageComp)`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const QrCode: FC<Props> = ({ name, qr = '', size = 100, url }) => {
  const margin = 2;
  const qrImgLink = qr || `${API_HOST}/qr?c=${url}&s=${size}&m=${margin}`;

  return (
    <StyledImageCard
      alt={`Scan to view ${name} on your mobile`}
      className="qr-img"
      src={qrImgLink}
      loadingIcon={
        <TailSpin
          height="60"
          width="60"
          color={theme.colors.themeColorPink}
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      }
    />
  );
};

export default QrCode;
