import React, { useEffect, useState, FC } from 'react';
import { getData } from 'src/utils';
import { LOCALE } from 'src/utils/constants';
import { Locale } from 'src/types';
import History from 'src/components/tiny/History';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  locale?: Locale;
}

const Education: FC<Props> = ({ title, locale = LOCALE.DEFAULT, ...rest }) => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    setData([]);
    getData('education', locale).then((result) => {
      if (result.isSucceed) {
        setData(result.data);
      }
    });
  }, [locale]);

  return <History data={data} title={title} {...rest} />;
};

export default Education;
