import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import { Hourglass } from 'react-loader-spinner';

const size = 100;

const mobileStyling = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > * {
    width: ${theme.getMobileVw(size)};
  }
`;

const tabletStyling = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > * {
    width: ${theme.rem(size)};
  }
`;

const StyledComponent = styled.div`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const Loader = () => {
  return (
    <StyledComponent>
      <Hourglass
        visible={true}
        ariaLabel="hourglass-loading"
        wrapperStyle={{}}
        wrapperClass=""
        colors={[theme.colors.themeColorBlue, theme.colors.themeColorPink]}
      />
    </StyledComponent>
  );
};

export default Loader;
