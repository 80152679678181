import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import { caseConversion } from 'src/utils';

interface Props {
  caseType?: 'UPPER' | 'LOWER' | 'TITLE';
  horizontalAlign?: 'left' | 'center' | 'right' | 'justify';
  tag?: string;
  title?: string;
}

const fontSize = 25;
const marginTop = 10;

const mobileStyling = css`
  color: ${theme.colors.themeColorBlue};
  font-size: ${theme.getMobileVw(fontSize)};
  font-weight: 700;
  margin-bottom: ${theme.getMobileVw(fontSize)};
  width: 100%;

  &:after {
    border-bottom: ${theme.getMobileVw(3)} solid ${theme.colors.themeColorBlue};
    content: '';
    display: block;
    height: 1px;
    margin-top: ${theme.getMobileVw(marginTop)};
    width: 100%;
  }
`;

const tabletStyling = css`
  color: ${theme.colors.themeColorBlue};
  font-size: ${theme.rem(fontSize)};
  font-weight: 700;
  margin-bottom: ${theme.rem(fontSize)};
  width: 100%;

  &:after {
    border-bottom: ${theme.rem(3)} solid ${theme.colors.themeColorBlue};
    content: '';
    display: block;
    height: 1px;
    margin-top: ${theme.rem(marginTop)};
    width: 100%;
  }
`;

const StyledComponent = styled.div`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const Title: FC<Props> = ({
  caseType = 'UPPER',
  horizontalAlign = 'left',
  tag = 'h3',
  title
}) => {
  if (!title) return null;

  const str = caseConversion(title, caseType);
  return str ? (
    <StyledComponent as={tag} style={{ textAlign: horizontalAlign }}>
      {str}
    </StyledComponent>
  ) : null;
};

export default Title;
