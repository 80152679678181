import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import { Locale } from 'src/types';
import { LOCALE, LOCAL_STORAGE_KEYS } from 'src/utils/constants';
import translationIcon from 'src/images/translation.svg';
import { isValidGaDomain } from 'src/utils/index';
import ReactGA from 'react-ga4';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  initialVisibility?: boolean;
  timeout?: number;
  locale?: Locale;
  setLocale: (locale: Locale) => void;
  setIsAppLoading?: Dispatch<SetStateAction<boolean>>;
}

const icoSize = 70;
const spacing = 20;

const mobileStyling = css`
  background-color: transparent;
  background-image: url(${translationIcon});
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  bottom: ${theme.getMobileVw(spacing)};
  cursor: pointer;
  display: block;
  height: ${theme.getMobileVw(icoSize)};
  outline: none;
  position: fixed;
  right: ${theme.getMobileVw(spacing)};
  width: ${theme.getMobileVw(icoSize)};
  z-index: 2;
`;

const tabletStyling = css`
  background-color: transparent;
  background-image: url(${translationIcon});
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  bottom: ${theme.rem(spacing)};
  cursor: pointer;
  display: block;
  height: ${theme.rem(icoSize)};
  outline: none;
  position: fixed;
  right: ${theme.rem(spacing)};
  width: ${theme.rem(icoSize)};
  z-index: 2;
`;

const StyledComponent = styled.button`
  ${tabletStyling}

  &:active,
  &:focus {
    ${tabletStyling}
  }

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}

    &:active,
    &:focus {
      ${mobileStyling}
    }
  }

  @media print {
    display: none;
  }
`;

const Translation: FC<Props> = ({
  initialVisibility = true,
  timeout = 3000,
  locale = LOCALE.DEFAULT,
  setLocale,
  setIsAppLoading,
  ...rest
}) => {
  const [show, setShow] = useState<boolean>(initialVisibility);
  let timeoutFn: any;

  const showIcon = () => {
    setShow(true);
    hideIcon(); // calling this function makes the icon dissapear after timeout
  };

  const hideIcon = (hideImemdiately = false) => {
    const miliseconds = hideImemdiately ? 0 : timeout;
    if (timeoutFn) {
      clearTimeout(timeoutFn);
    }
    timeoutFn = setTimeout(() => {
      setShow(false);
    }, miliseconds);
  };

  const handleClick = () => {
    hideIcon(true);
    const toLocale = locale === LOCALE.ZH ? LOCALE.EN : LOCALE.ZH;
    setLocale(toLocale);
    if (isValidGaDomain()) {
      ReactGA.event({
        category: 'User Interaction',
        action: 'custom_language_toggle',
        label: `Language toggled to ${toLocale}`
      });
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALE, toLocale);
  };

  window.addEventListener('scroll', showIcon);
  window.addEventListener('click', showIcon);
  window.addEventListener('mousemove', showIcon);
  window.addEventListener('mousedown', showIcon);

  return (
    <>
      {show && (
        <StyledComponent
          onScroll={showIcon}
          onClick={handleClick}
          role="button"
          tabIndex={0}
          aria-hidden="true"
          {...rest}
        />
      )}
    </>
  );
};

export default Translation;
