import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';

const columnGap = '5%';
const maxWidth = 1048;

const centerizeStyles = css`
  margin-left: auto;
  margin-right: auto;
`;

export const Centerize = styled.div.attrs((props) => ({
  ...props,
  className: `centerize${props.className ? ` ${props.className}` : ''}`
}))`
  ${centerizeStyles}
`;

const containerMobile = css`
  align-content: normal;
  display: flex;
  flex-direction: column;
  max-width: ${theme.getMobileVw(maxWidth)};
  justify-content: flex-start;
  padding-left: ${theme.getMobileVw(theme.generalSpacing)};
  padding-right: ${theme.getMobileVw(theme.generalSpacing)};
  width: 100%;
`;

const containerTablet = css`
  align-content: flex-start;
  display: flex;
  flex-direction: row;
  max-width: ${theme.rem(maxWidth)};
  justify-content: space-between;
  padding-left: ${theme.rem(theme.generalSpacing)};
  padding-right: ${theme.rem(theme.generalSpacing)};
  width: 100%;
`;

export const Container = styled.div.attrs((props) => ({
  ...props,
  className: `${props.className ? ` ${props.className}` : ''}`
}))`
  ${containerTablet}
  ${centerizeStyles}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${containerMobile}
    ${centerizeStyles}
  }

  @media print {
    ${containerTablet}
    max-width: 1100px;

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${containerMobile}
    }
  }
`;

const letfPanelMobile = css`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  order: 0;
  justify-content: space-between;
  width: 100%;
`;

const leftPanelTablet = css`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  order: 1;
  justify-content: space-between;
  width: calc(70% - (${columnGap} / 2));
`;

export const LeftPanel = styled.section.attrs((props) => ({
  ...props,
  className: `${props.className ? ` ${props.className}` : ''}`
}))`
  ${leftPanelTablet}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${letfPanelMobile}
  }

  @media print {
    ${leftPanelTablet}
    width: calc(75% - (${columnGap} / 2));

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${letfPanelMobile}
    }
  }
`;

const rightPanelMobile = css`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  order: 0;
  width: auto;
`;

const rightPanelTablet = css`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  order: 2;
  width: calc(30% - (${columnGap} / 2));
`;

export const RightPanel = styled.section.attrs((props) => ({
  ...props,
  className: `${props.className ? ` ${props.className}` : ''}`
}))`
  ${rightPanelTablet}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${rightPanelMobile}
  }

  @media print {
    ${rightPanelTablet}
    width: calc(25% - (${columnGap} / 2));

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${rightPanelMobile}
    }
  }
`;
