import React, { useEffect, useState, FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import { getData } from 'src/utils';
import { LOCALE } from 'src/utils/constants';
import { Locale } from 'src/types';
import Title from 'src/components/tiny/Title';
import Loader from 'src/components/tiny/Loader';

// Define the structure of each knowledge item
interface KnowledgeItem {
  knowledge: string;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  locale?: Locale;
}

const fontSize = theme.generalFontSize;
const gap = 10;
const padding = 10;

const mobileStyling = css`
  margin-bottom: ${theme.getMobileVw(theme.rightSidebarBottomSpacing)};
  overflow: hidden;
  padding-bottom: ${theme.getMobileVw(theme.rightSidebarBottomSpacing)};
  width: 100%;

  .knowledges {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    width: calc(100% + ${theme.getMobileVw(gap)});

    > .each-knowledge {
      background-color: #eaeaea;
      border-bottom-color: #bdbcbc;
      border-bottom-color: ${theme.colors.themeColorBlue};
      border-bottom: ${theme.getMobileVw(2.5)} solid
        ${theme.colors.themeColorBlue};
      color: #000;
      display: flex;
      font-size: ${theme.getMobileVw(fontSize)};
      font-weight: 700;
      margin-bottom: ${theme.getMobileVw(gap)};
      margin-right: ${theme.getMobileVw(gap)};
      padding: ${theme.getMobileVw(padding / 2)} ${theme.getMobileVw(padding)};
    }
  }
`;

const tabletStyling = css`
  margin-bottom: ${theme.rem(theme.rightSidebarBottomSpacing)};
  overflow: hidden;
  padding-bottom: ${theme.rem(theme.rightSidebarBottomSpacing)};
  width: 100%;

  .knowledges {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
    margin: 0;
    padding: 0;
    width: calc(100% + (${theme.rem(gap)} / 2));

    > .each-knowledge {
      background-color: #eaeaea;
      border-bottom-color: #bdbcbc;
      border-bottom-color: ${theme.colors.themeColorBlue};
      border-bottom: ${theme.rem(1.5)} solid ${theme.colors.themeColorBlue};
      color: #000;
      display: flex;
      font-size: ${theme.rem(fontSize)};
      font-weight: 700;
      margin-bottom: ${theme.rem(gap)};
      margin-right: ${theme.rem(gap)};
      padding: ${theme.rem(padding / 2)} ${theme.rem(padding)};
    }
  }
`;

const StyledComponent = styled.div`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const Knowledge: FC<Props> = ({ title, locale = LOCALE.DEFAULT, ...rest }) => {
  const [data, setData] = useState<KnowledgeItem[]>([]); // Use the KnowledgeItem type for state

  useEffect(() => {
    setData([]);
    getData('knowledge', locale).then((result) => {
      if (result.isSucceed) {
        setData(result.data);
      }
    });
  }, [locale]);

  return (
    <StyledComponent {...rest}>
      <Title title={title} caseType="UPPER" />
      {data?.length > 0 ? (
        <ul className="knowledges">
          {data.map((each, index) => (
            <li className="each-knowledge" key={index}>
              {each.knowledge}
            </li>
          ))}
        </ul>
      ) : (
        <Loader />
      )}
    </StyledComponent>
  );
};

export default Knowledge;
