import React, { useEffect, useState, FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import { getData } from 'src/utils';
import { LOCALE } from 'src/utils/constants';
import { Locale } from 'src/types';
import Title from 'src/components/tiny/Title';
import QrCard from 'src/components/tiny/QrCard';
import Loader from 'src/components/tiny/Loader';

interface QrCardType {
  project: string;
  url: string;
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  locale?: Locale;
}

const mobileStyling = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${theme.getMobileVw(theme.rightSidebarBottomSpacing)};
  padding-bottom: ${theme.getMobileVw(theme.rightSidebarBottomSpacing)};
  width: 100%;

  > .title {
    width: 100%;
  }
`;

const tabletStyling = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0;
  padding-bottom: 0;
  width: 100%;

  > .title {
    width: 100%;
  }
`;

const StyledComponent = styled.div`
  ${tabletStyling}

  @media screen and (max-width: ${theme.breakpoints.tablet}px) {
    ${mobileStyling}
  }

  @media print {
    ${tabletStyling}

    // BEGIN :: this styling makes items in qr card align side-by-side
    // .qrcard {
    //   width: calc((100% / 2) - ${theme.rem(theme.generalSpacing / 2)});
    //   flex: 0 0 calc((100% / 2) - ${theme.rem(theme.generalSpacing / 2)});
    // }
    // END :: this styling makes items in qr card align side-by-side

    @media screen and (max-width: ${theme.breakpoints.tablet}px) {
      ${mobileStyling}
    }
  }
`;

const Work: FC<Props> = ({ title, locale = LOCALE.DEFAULT, ...rest }) => {
  const [data, setData] = useState<QrCardType[]>([]);

  useEffect(() => {
    setData([]);
    getData('work', locale).then((result) => {
      if (result.isSucceed) {
        setData(result.data);
      }
    });
  }, [locale]);

  return (
    <StyledComponent {...rest}>
      <Title title={title} caseType="UPPER" />
      {data?.length > 0 ? (
        data.map((each, index) => {
          return (
            <QrCard
              className="qrcard"
              key={index}
              name={each.project}
              url={each.url}
            />
          );
        })
      ) : (
        <Loader />
      )}
    </StyledComponent>
  );
};

export default Work;
